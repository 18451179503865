<template>
  <div class="simulation-list">
    <transition-group
      name="fade"
      tag="b-row"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <b-col v-for="(item, index) in items" class="mb-4" md="3" :key="`sim-card-${index}`">
        <simulation-card
          :avatar="item.avatar"
          :client="item.client"
          :code="item.code"
          :endDate="item.endDate"
          :facilitators="item.facilitators"
          :location="item.location"
          :participants="item.participants"
          :showActions="showSimulationActions(item)"
          :startDate="item.startDate"
          :status="item.status"
          :statusName="item.statusName"
          :title="item.name"
          @click.native="onSimulationClick(item)"
          @delete="onSimulationDelete(item)"
          @edit="onSimulationEdit(item)"
        ></simulation-card>
      </b-col>
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SimulationCard from '@/components/Cards/SimulationCard.vue'
export default {
  name: 'SimulationList',
  components: {
    SimulationCard
  },
  props: {
    items: {
      type: Array,
      default: []
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions(['fetchSimulationByCode']),
    ...mapMutations(['setCurrentSimulation']),
    async getSimulationDetails(item) {
      await this.fetchSimulationByCode(item.code)
    },
    async onSimulationClick(item) {
      this.setCurrentSimulation(item)
      this.selected = await _.cloneDeep(this.item)
      await this.$router.push(`/my-simulations/${item.code}`)
    },
    async onSimulationDelete(item) {
      this.$emit('delete', item)
    },
    async onSimulationEdit(item) {
      this.$emit('edit', item)
    },
    showSimulationActions(item) {
      return [2, 3].includes(item.roleId)
    }
  },
  computed: {
    ...mapGetters(['currentSimulation']),
    selected: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
