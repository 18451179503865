<template>
  <div class="my-simulations main pt-4">
    <div class="container">
      <div class="d-flex mb-3 px-3">
        <h2 class="align-self-center mb-0">My Simulations</h2>
        <multiselect
          class="align-self-center simulation-status-selection ml-3"
          label="name"
          placeholder="Select status..."
          v-model="selectedSimulationStatus"
          track-by="id"
          :close-on-select="false"
          :multiple="true"
          :options="simulationStatus"
          :showLabels="false"
          :searchable="false"
          :taggable="true"
        >
          <template #option="props">
            <b-badge :variant="props.option.variant">{{ props.option.name }}</b-badge>
          </template>
          <template #tag="props">
            <b-badge class="mr-2" :variant="props.option.variant">{{ props.option.name }}</b-badge>
          </template>
        </multiselect>
        <div class="align-self-center d-flex ml-auto">
          <b-input
            class="align-self-center mr-3"
            placeholder="Search"
            v-model="searchQuery"
          ></b-input>
          <b-button
            v-if="canCreateSimulation"
            class="align-self-center no-wrap"
            size="sm"
            variant="primary"
            @click="onGameCreate"
          >
            <span>Create Game</span>
          </b-button>
        </div>
      </div>
      <div class="list-scroll px-3 py-2">
        <simulation-list
          :items="filteredSimulations"
          v-model="selected"
          @edit="onSimulationEdit"
        ></simulation-list>
      </div>
    </div>

    <b-modal hide-header hide-footer v-model="showEditModal" @hidden="onEditModalHide">
      <simulation-form
        :editMode="!isNewSimulation"
        v-model="newSimulation"
        @cancel="onSimulationCancel"
        @saved="onSimulationSave"
      ></simulation-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { helperMixin } from '@/mixins/helperMixin'
import { simulationStatus } from '@/constants/config'
import Multiselect from 'vue-multiselect'
import SimulationForm from '@/components/Form/SimulationForm.vue'
import SimulationList from '@/components/Listing/SimulationList.vue'
export default {
  components: {
    Multiselect,
    SimulationForm,
    SimulationList
  },
  data() {
    return {
      newSimulation: this.initiateSimulation(),
      showEditModal: false,
      searchQuery: '',
      selected: null,
      selectedSimulationStatus: [],
      simulationsData: [],
      simulationStatus
    }
  },
  mixins: [helperMixin],
  methods: {
    ...mapMutations(['resetSimulationTeams', 'setCurrentSimulation', 'setCurrentSimulationTeam']),
    initiateSimulation() {
      return {
        avatar: null,
        client: '',
        endDate: '',
        location: '',
        name: '',
        startDate: ''
      }
    },
    onEditModalHide() {
      this.newSimulation = this.initiateSimulation()
    },
    onGameCreate() {
      this.triggerEditModal(true)
    },
    onSimulationCancel() {
      this.triggerEditModal(false)
    },
    onSimulationEdit(simulation) {
      this.newSimulation = _.cloneDeep(simulation)
      this.triggerEditModal(true)
    },
    onSimulationSave() {
      this.triggerEditModal(false)
    },
    resetSimulationState() {
      this.resetSimulationTeams()
      this.setCurrentSimulation(null)
      this.setCurrentSimulationTeam(null)
    },
    triggerEditModal(value) {
      this.showEditModal = value
    }
  },
  computed: {
    ...mapGetters(['simulations']),
    filteredSimulations() {
      return this.simulationsData.filter((simulation) => {
        return this.selectedSimulationStatus && this.selectedSimulationStatus.length
          ? this.selectedSimulationStatus.some((status) => status.id === simulation.status)
          : true
      })
    },
    isNewSimulation() {
      return !this.newSimulation.id
    }
  },
  mounted() {
    this.resetSimulationState()
  },
  watch: {
    searchQuery: {
      handler(newValue) {
        if (newValue) {
          const simulatoionData = this.simulations.filter((simulation) => {
            // Check if the search query is found in either the simulation name or client name
            const nameMatch = simulation.name.toLowerCase().includes(newValue.toLowerCase())
            const clientMatch = simulation.client.toLowerCase().includes(newValue.toLowerCase())

            return nameMatch || clientMatch
          })

          this.simulationsData = _.cloneDeep(simulatoionData)
        } else {
          this.simulationsData = _.cloneDeep(this.simulations)
        }
      },
      immediate: true
    },
    simulations: {
      handler(newValue) {
        this.simulationsData = _.cloneDeep(newValue)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.list-scroll {
  height: calc(100vh - 170px);
  overflow-x: hidden;
  overflow-y: auto;
}

.multiselect-fixed-width {
  width: 300px; /* Set a fixed or maximum width */
}

.multiselect__tags {
  white-space: nowrap; /* Prevent the tags from wrapping */
  display: flex;
  flex-wrap: nowrap; /* Ensure the tags stay in a single line */
  overflow-x: auto; /* Scroll if the tags exceed the width */
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
