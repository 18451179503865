<template>
  <div class="main">
    <div class="container base-page d-flex">
      <div v-if="currentSimulation" class="align-items-center my-auto">
        <h1 class="w-100 text-center" v-html="$t('pages.home.title')"></h1>

        <b-card-group deck class="my-5">
          <b-card
            v-for="(post, index) in posts"
            :key="`post-${index}`"
            body-class="text-center p-0"
            class="home-card animate__animated animate__slideInUp"
            @click="onSimulationClick(post)"
          >
            <b-card-body>
              <b-avatar
                :src="post.thumb"
                class="align-center"
                size="100"
                variant="light"
              ></b-avatar>
              <h2 class="nav-link active my-3">{{ post.title }}</h2>
              <p v-html="post.subtitle"></p>
            </b-card-body>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'
import { homePage } from '@/data/pageContents'
export default {
  data() {
    return {
      posts: homePage,
      appTitle: config.VUE_APP_CLIENT || process.env.VUE_APP_CLIENT
    }
  },
  mixins: [dataPreloadMixin],
  methods: {
    onSimulationClick(post) {
      if (!this.currentSimulation) return
      this.$router.push({ name: post.link, params: { dynamic_code: this.currentSimulation.code } })
    }
  },
  computed: {
    ...mapGetters(['currentSimulation'])
  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - 300px);
}

h1 {
  font-family: 'Nunito Light';
}

h1 span {
  font-family: 'Nunito Bold';
}

.home-card:hover {
  cursor: pointer;
  transform: scale(1.08);
  box-shadow: 3px 3px 12px 0 rgba(0, 0, 0, 0.2);
}
</style>
