<template>
  <b-form class="simulation-form d-flex flex-column" @submit.prevent="onSimulationSubmit">
    <h4>{{ formHeader }}</h4>
    <b-avatar
      button
      class="mb-2 mx-auto avatar-edit"
      badge-variant="transparent"
      variant="light"
      size="5em"
      :src="avatarUrl"
      @click="onAvatarClick"
    >
      <template #badge v-if="avatarUrl">
        <i class="simple-icon-camera"></i>
      </template>
    </b-avatar>
    <input
      id="fileUpload"
      ref="avatarFile"
      type="file"
      accept="image/jpg,image/png"
      @change="onAvatarFileChange"
      hidden
    />
    <base-input
      label="Client Name:"
      placeholder="Client Name"
      v-model="simulation.client"
      required
      validate
      :error="setError($v.simulation.client, 'Client Name')"
      :loading="processingSimulation"
      :v="$v.simulation.client"
    ></base-input>
    <base-input
      label="Simulation Name:"
      placeholder="Simulation Name"
      v-model="simulation.name"
      required
      validate
      :error="setError($v.simulation.name, 'Simulation Name')"
      :loading="processingSimulation"
      :v="$v.simulation.name"
    ></base-input>
    <base-input
      label="Location:"
      placeholder="Location"
      required
      validate
      v-model="simulation.location"
      :error="setError($v.simulation.location, 'Location')"
      :loading="processingSimulation"
      :v="$v.simulation.location"
    ></base-input>
    <base-date-picker
      id="start-date"
      label="Start Date:"
      placeholder="Start Date"
      required
      validate
      v-model="simulation.startDate"
      :error="setError($v.simulation.startDate, 'Start Date')"
      :loading="processingSimulation"
      :v="$v.simulation.startDate"
    ></base-date-picker>
    <base-date-picker
      id="end-date"
      label="End Date:"
      placeholder="End Date"
      required
      validate
      v-model="simulation.endDate"
      :error="setError($v.simulation.endDate, 'End Date')"
      :loading="processingSimulation"
      :v="$v.simulation.endDate"
    ></base-date-picker>
    <div class="align-self-center ml-auto mt-3">
      <b-button variant="light" size="sm" @click="onCancelClick">
        <span class="label">Cancel</span>
      </b-button>
      <processing-button
        class="ml-2"
        size="sm"
        variant="primary"
        :label="editMode ? 'Update' : 'Save'"
        :processing="processingSimulation"
      ></processing-button>
    </div>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorMessages } from '@/utils/validators'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import BaseDatePicker from '@/components/Common/BaseDatePicker.vue'
import BaseInput from '@/components/Common/BaseInput.vue'
import BaseSelection from '@/components/Selections/BaseSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
export default {
  components: {
    BaseDatePicker,
    BaseInput,
    BaseSelection,
    ProcessingButton
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      avatarUrl: '',
      simulationForm: new FormData()
    }
  },
  mixins: [validationMixin],
  validations: {
    simulation: {
      client: { required },
      name: { required },
      location: { required },
      startDate: { required },
      endDate: {
        required,
        isAfterStartDate(value) {
          if (value) {
            return new Date(value) > new Date(this.simulation.startDate)
          }
          return true
        }
      }
    }
  },
  methods: {
    ...mapActions(['addSimulation', 'updateSimulation']),
    onAvatarClick() {
      this.$refs['avatarFile'].click()
    },
    onAvatarFileChange(event) {
      const files = event.target.files
      const file = files[0]
      this.simulation.avatar = file
      this.avatarUrl = URL.createObjectURL(file)
    },
    onCancelClick() {
      this.$emit('cancel')
    },
    async onSimulationSubmit() {
      await this.$v.simulation.$touch()
      if (this.$v.simulation.$invalid) {
        return
      }

      // this.simulationForm.append('avatar', this.simulation.avatar)
      this.simulationForm.append('client', this.simulation.client)
      this.simulationForm.append('end_date', this.simulation.endDate)
      this.simulationForm.append('location', this.simulation.location)
      this.simulationForm.append('name', this.simulation.name)
      this.simulationForm.append('start_date', this.simulation.startDate)

      if (this.avatarUrl && this.isAvatarUrlLocal) {
        this.simulationForm.append('avatar', this.simulation.avatar)
      }

      if (this.editMode) {
        this.simulationForm.append('id', this.simulation.id)
        await this.updateSimulation({ id: this.simulation.id, payload: this.simulationForm })
      } else {
        await this.addSimulation(this.simulationForm)
      }

      this.$emit('saved')
    },
    setError(item, name) {
      if (item.$dirty) {
        let errorMessage = ''
        if (item.required === false) {
          errorMessage = `${name} ${errorMessages.required}`
          return errorMessage
        }

        if (item.isAfterStartDate === false) {
          errorMessage = `The Start Date should not be later than the End Date`
          return errorMessage
        }
      }
    }
  },
  computed: {
    ...mapGetters('loading', ['processingSimulation']),
    formHeader() {
      return this.editMode ? 'Edit Simulation' : 'New Simulation'
    },
    isAvatarUrlLocal() {
      const url = this.avatarUrl
      const regex = /^blob:/
      return regex.test(url)
    },
    simulation: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
