<template>
  <div class="d-flex">
    <span class="text-center" style="width: 20px">
      <i
        class="align-self-center"
        :class="[iconFilled ? 'fas' : 'fal', `fa-${icon}`, `text-${variant}`]"
      ></i>
    </span>
    <span class="align-self-center font-extra-light ml-2">{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    iconFilled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Number, String],
      default: ''
    },
    variant: {
      type: String,
      default: 'dark'
    }
  }
}
</script>

<style lang="scss" scoped></style>
